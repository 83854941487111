import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentContainer"
import TwoColumnComponent from "../../components/twoColumnComponent"

class Tickets extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const node = data.allMarkdownRemark.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={node.frontmatter.seoTitle || node.frontmatter.title}  description={node.frontmatter.seoDescription}/>
        <div className="content">
          <ContentContainer paddingTop paddingMobile paddingMobileTop>
            <TwoColumnComponent title={node.frontmatter.title} titleContent={node.frontmatter.subtitle} backbutton={false} linkDestination="/timeline">
                          <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
            </TwoColumnComponent>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Tickets

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/day/tickets.md/"}}) {
      edges {
        node {
          fields {
            slug
          }
          html
          fileAbsolutePath
          frontmatter {
            title
            subtitle
            date
            seoTitle
            seoDescription
          }
        }
      }
    }
    
  }
`
